export const carrier = [
	{
		key: '100062',
		name: 'Yamato(JP)',
		value: 'Yamato',
		rule: '^2994\\d{8}$'
	},
	{
		key: '100040',
		name: 'Sagawa(JP)',
		value: 'Sagawa',
		rule: '^355\\d{9}'
	},
	{
		key: '190405',
		rule: '^FLY[0-9A-Z]{9,12}$',
		ruleDesc: 'FLY## *** *** *** * YQ',
		value: 'YIHAO',
		name: '壹号专线'
	},
	{
		key: '100001',
		rule: '^\\d{10,11}$',
		value: 'DHL',
		name: 'DHL'
	},
	{
		key: '07041',
		rule: '^0034\\d{16}$',
		value: 'DHL Paket',
		name: 'DHL_Paket'
	},
	{
		key: '100002',
		rule:
			'\\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\\dT]\\d\\d\\d ?\\d\\d\\d\\d ?\\d\\d\\d)\\b',
		name: 'UPS',
		value: 'UPS'
	},
	{
		key: '21051',
		name: 'USPS',
		value: 'USPS',
		rule: '^(420|94001|94055|92|93)\\d+',
		ruleDesc:
			'A# *** *** *** US,C# *** *** *** US,D# *** *** *** US,E# *** *** *** US,G# *** *** *** US,H# *** *** *** US,L# *** *** *** US,R# *** *** *** US,S# *** *** *** US,T# *** *** *** US,U# *** *** *** US,V# *** *** *** US,82 *** *** **,83 *** *** **,M4 *** *** **,V0 *** *** **,CH00 *** *** *,LH00 *** *** *,94001 *** *** *** *** *** **,94055 *** *** *** *** *** **,92 *** *** *** *** *** *** *** ***,93 *** *** *** *** *** *** *** ***,420 *** *** *** *** *** *** *** *** ***,420 *** *** *** *** *** *** *** *** *** *** *,E1905 *** * !*** **** *** *** *** *** *** *** * **,E1906 *** * !*** **** *** *** *** *** *** *** * **,L1905 *** * !*** **** *** *** *** *** *** *** * **,L1906 *** * !*** **** *** *** *** *** *** *** * **'
	},
	{
		key: '03011',
		name: 'China Post',
		value: 'CHINA_POST',
		rule: '^[ABCEFLPRSUV].*CN$'
	},
	{
		key: '03013',
		name: 'China EMS',
		value: 'CHINA_EMS',
		rule: '^[K].*CN$'
	},
	{
		key: '01151',
		name: 'Australia Post',
		value: 'AUSTRALIA_POST',
		rule: '^33[0-9A-Z]{21}$'
	},
	{
		key: '190112',
		name: 'UBI',
		value: 'UBI'
		// rule: '^400631\\d{10}$',
	},
	{
		key: '03041',
		name: 'Canada POST',
		value: 'CANADA_POST',
		rule: '^\\d{16}$'
	},
	{
		key: '190380',
		name: '六脉小包',
		value: 'LIUMAI',
		rule: '^LM\\d{10}'
	},
	{
		key: '11031',
		name: 'Royal Mail',
		value: 'ROYAL_MAIL',
		rule: '^[ABFGJKLMNPQRSTUVWXYZ]{2}\\d{9}GB'
	},
	{
		key: '190094',
		name: '4PX',
		value: '4PX',
		rule: '^4PX\\d{13}[A-Z]{2}'
	},
	{
		key: '190094',
		name: 'Hermes(DE)',
		value: 'Hermes',
		rule: '^H10[012]\\d{16}$'
	},
	{
		key: '06051',
		name: 'La Poste(FR)',
		value: 'LA_POSTE',
		rule: '^(6A|6G|LP)[0-9A-Z]{11}$'
	},
	{
		key: '100007',
		name: 'DPD(DE)',
		value: 'DPD_DE',
		rule: '^0\\d{13}$'
	},
	{
		key: '09071',
		name: 'Poste Italiane',
		value: 'POSTE_ITALIANE',
		rule: '^(9C|5P)[0-9A-Z]{11}$'
	},
	{
		key: '190094',
		name: 'GLS Spain(ES)',
		value: 'GLS_SPAIN',
		rule: '^618\\d{11}$'
	},
	{
		key: '14044',
		name: 'PostNL',
		value: 'PostNL',
		rule: '^LS\\d{9}NL$'
	},
	{
		key: '100017',
		name: 'Yodel',
		value: 'YODEL',
		rule: '^(JD|JJD)\\d{16}$'
	},
	{
		key: '190008',
		name: 'YunExpress',
		value: 'YUN_EXPRESS',
		rule: '^YT\\d{16}$'
	},
	{
		key: '190491',
		name: '博远运通(MORELINK)',
		value: 'MORELINK',
		rule: '(^(81|91|41)\\d{13}$)|(^DH\\d{12}$)'
	},
	{
		key: '100003',
		rule: '(\\b96\\d{20}\\b)|(\\b\\d{15}\\b)|(\\b\\d{12}\\b)',
		name: 'Fedex',
		value: 'FEDEX'
	},
	{
		key: '190208',
		name: 'CNE递一物流',
		value: 'CNE',
		rule: '(^DY)|(^3A5V)\\d{8,}$'
	},
	{
		key: '1000000',
		name: '韬博',
		value: 'TOPEST',
		rule: '^R60087\\d{10}$'
	},
	{
		key: '100331',
		name: 'Evri',
		value: 'Evri',
		rule: '^H\\d{2,3}[A-Z]{2,3}\\d{10}$'
	},
	{
		key: '100030',
		name: 'CDEK',
		value: 'CDEK',
		rule: ''
	},
	{
		key: '19181',
		name: 'Correos Spain',
		value: 'CORREOS_SPAIN',
		rule: '^PQ56GB\\d{16}[A-Z]{1}'
	},
	{
		key: '100300',
		name: 'GLOBALTRANS',
		value: 'GLOBALTRANS',
		rule: '^GBT\\d+'
	},
	{
		key: '7044',
		name: 'Deutsche Post Mail',
		value: 'DEUTSCHE_POST',
		rule: '^(LY)[0-9]{9}DE$'
	}
]
export const carrier_cn = [
	{ value: 'SHUNFENG', name: '顺丰', rule: '' },
	{ value: 'YTO', name: '圆通快递', rule: '' },
	{ value: 'ZTO', name: '中通快递', rule: '' },
	{ value: 'DPK', name: '德邦快递', rule: '' },
	{ value: 'HUOLALA', name: '货拉拉', rule: '' },
	{ value: 'SUHUI', name: '速惠物流', rule: '' }
]
export const carrierMatch = (code: string): any => {
	if (!code) return null
	for (const item of [...carrier, ...carrier_cn]) {
		if (!item.rule) continue
		const regex = new RegExp(item.rule)
		const isMatch = regex.test(code)
		if (isMatch) {
			return {
				name: item.name,
				value: item.value
			}
		}
	}
	return null
}

export const carrierNameMatchByKey = (key: string): any => {
	if (!key) return null
	for (const item of carrier) {
		if (!item.key) continue
		if (item.key == key) {
			return item.name
		}
	}
	return null
}
