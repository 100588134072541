
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { carrierMatch } from '@/utils/carrier'

@Component({
	components: {}
})
export default class extends Emitter {
	private codeTypes: any = {
		package: {
			label: '包裹',
			value: 'package',
			method: 'post',
			endpoint: 'v1/jobline/package-code/batch'
		},
		box: {
			label: '箱子',
			value: 'box',
			method: 'post',
			endpoint: 'v1/jobline/box-feeder/batch'
		},
		outbound: {
			label: '出库单',
			value: 'outbound',
			method: 'post',
			endpoint: 'v1/jobline/warehouse-record/batch'
		}
	}
	private inputCodeStr = ''
	private codesList: any = []
	private editForm = {
		type: 'package',
		feeder_company: '',
		feeder_code: '',
		stockout_company: '',
		stockout_code: '',
		express_company: '',
		express_code: ''
	}
	private codeExpressCode: any = {}
	@Watch('searchForm.logisticsCode')
	onInputLogisticsCode(value: string) {
		console.log(value)
	}
	private expressCompany: any = []
	private validateForm = {
		codes: [{ required: true, message: '请输入单号', trigger: 'blur' }]
	}
	async parseCode() {
		const codes: any[] = Array.from(
			new Set(
				this.inputCodeStr
					.trim()
					.split(/[;,\n]/)
					.map(item => {
						if (item.trim()) {
							const [code, name] = item.split(/[\t]{1,}/g)
							if (name) {
								const expressCode = name.trim().replace(/[\s\t]/g, '/')
								this.codeExpressCode[code] = {
									code: expressCode,
									carrier: carrierMatch(expressCode)
										? carrierMatch(expressCode).value
										: ''
								}
							}
							return code.trim()
						}
					})
			)
		)
		this.inputCodeStr = ''
		const codesList = this.codesList.map((item: any) => item.code)
		const queryCodeRes = await this.queryCodes(this.editForm.type, codes)
		const {
			feeder_company,
			feeder_code,
			stockout_company,
			stockout_code,
			express_company,
			express_code
		} = this.editForm
		for (const code of codes) {
			if (codesList.includes(code)) continue
			const codeInfo = queryCodeRes[code] || {
				feeder_company,
				feeder_code,
				stockout_company,
				stockout_code,
				express_company,
				express_code
			}
			const codeItem = {
				code,
				feeder_company: codeInfo.feeder_company || feeder_company,
				feeder_code: (codeInfo.feeder_code || feeder_code).trim(),
				stockout_company: (
					codeInfo.stockout_company || stockout_company
				).trim(),
				stockout_code: (codeInfo.stockout_code || stockout_code).trim(),
				express_company: (codeInfo.express_company || express_company).trim(),
				express_code: (codeInfo.express_code || express_code).trim()
			}

			if (this.codeExpressCode[code]) {
				codeItem.express_company = this.codeExpressCode[code].carrier
				codeItem.express_code = this.codeExpressCode[code].code
			}
			this.codesList.push(codeItem)
		}
	}
	emitInputCode(codeName: string) {
		if (codeName.endsWith('_code')) {
			const companyCodeName: any = codeName.replace(/code/, 'company')
			const carrier = carrierMatch((this.editForm as any)[codeName])
			if (carrier) {
				;(this.editForm as any)[companyCodeName] = carrier.value
				this.emitInputCode(companyCodeName)
			}
		}
		for (const item of this.codesList) {
			item[codeName] = (this.editForm as any)[codeName]
		}
	}
	emitRowInputCode($index: number, row: any, codeName: string) {
		const carrier = carrierMatch(row[codeName])
		if (carrier) {
			this.codesList[$index][codeName.replace(/code/, 'company')] =
				carrier.value
		}
	}
	codeTypeChange() {
		this.codesList = []
	}
	removeCode({ row, $index }: any) {
		this.codesList.splice($index, 1)
	}
	created() {
		this.getExpressCompany()
	}
	async queryCodes(type: string, codes: string[]) {
		const { data } = await this.$axios.post(
			'v1/jobline/package-code/batch/search',
			{
				type,
				codes
			}
		)

		const codesMap: any = {}
		data.forEach((item: any) => {
			codesMap[item.code] = item
		})
		return codesMap
	}
	async getExpressCompany() {
		const { data } = await this.$axios.get('v1/jobline/express-company', {})

		this.expressCompany = data
	}
	async savePackageCodes() {
		const { type } = this.editForm
		const postData: any = {}
		const { endpoint } = this.codeTypes[type]
		const { method = 'post' } = this.codeTypes[type]

		if (this.codesList.length === 0) {
			this.$message.error('请输入包裹号/箱号')
			return
		}

		if (type == 'outbound') {
			postData.warehouse_record_datas = []
			for (const item of this.codesList) {
				postData.warehouse_record_datas.push({
					code: item.code,
					dock_company: item.stockout_company,
					dock_num: item.stockout_code
				})
			}
		} else if (type === 'box') {
			postData.box_datas = []
			for (const item of this.codesList) {
				postData.box_datas.push({
					code: item.code,
					feeder_company: item.feeder_company,
					feeder_code: item.feeder_code
				})
			}
		} else if (type === 'package') {
			postData.package_attr_data = []
			for (const item of this.codesList) {
				postData.package_attr_data.push({
					code: item.code,
					feeder_company: item.feeder_company,
					feeder_code: item.feeder_code,
					express_company: item.express_company,
					express_code: item.express_code
				})
			}
		}

		const data = await this.$axios({
			method,
			url: endpoint,
			data: postData
		}).catch(e => {
			// this.$message.error(e.message)
			return ''
		})
		if (data) {
			this.$message.success('更新成功')
			this.codesList = []
		}
	}
}
