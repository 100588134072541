var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("单号回填")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "container info-card" },
              [
                _c(
                  "el-form",
                  {
                    ref: "search-form",
                    staticClass: "search-form",
                    attrs: { model: _vm.editForm, "label-width": "90px" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return (() => {}).apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "类型", prop: "type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择类型" },
                            on: { change: _vm.codeTypeChange },
                            model: {
                              value: _vm.editForm.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "type", $$v)
                              },
                              expression: "editForm.type",
                            },
                          },
                          _vm._l(_vm.codeTypes, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "code-form" },
                      [
                        _vm.editForm.type == "outbound"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input",
                                attrs: {
                                  label: "出仓单号",
                                  prop: "stockout_code",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "出仓单号",
                                      clearable: "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.emitInputCode(
                                          "stockout_code"
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.emitInputCode(
                                          "stockout_code"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.editForm.stockout_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "stockout_code",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "editForm.stockout_code",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          slot: "prepend",
                                          placeholder: "请选择运输商",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.emitInputCode(
                                              "stockout_company"
                                            )
                                          },
                                        },
                                        slot: "prepend",
                                        model: {
                                          value: _vm.editForm.stockout_company,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm,
                                              "stockout_company",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editForm.stockout_company",
                                        },
                                      },
                                      _vm._l(
                                        _vm.expressCompany,
                                        function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.value,
                                              attrs: {
                                                label: item.title,
                                                value: item.value,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " [" +
                                                  _vm._s(item.label) +
                                                  "]" +
                                                  _vm._s(item.title) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.editForm.type == "box" ||
                        _vm.editForm.type == "package"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input",
                                attrs: {
                                  label: "头程单号",
                                  prop: "feeder_code",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "头程单号",
                                      clearable: "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.emitInputCode("feeder_code")
                                      },
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.emitInputCode("feeder_code")
                                      },
                                    },
                                    model: {
                                      value: _vm.editForm.feeder_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "feeder_code",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "editForm.feeder_code",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          slot: "prepend",
                                          placeholder: "请选择运输商",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.emitInputCode(
                                              "feeder_company"
                                            )
                                          },
                                        },
                                        slot: "prepend",
                                        model: {
                                          value: _vm.editForm.feeder_company,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm,
                                              "feeder_company",
                                              $$v
                                            )
                                          },
                                          expression: "editForm.feeder_company",
                                        },
                                      },
                                      _vm._l(
                                        _vm.expressCompany,
                                        function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.value,
                                              attrs: {
                                                label: item.title,
                                                value: item.value,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " [" +
                                                  _vm._s(item.label) +
                                                  "]" +
                                                  _vm._s(item.title) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.editForm.type == "package"
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "form-item-input",
                                attrs: {
                                  label: "末端单号",
                                  prop: "express_code",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "末端单号",
                                      clearable: "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.emitInputCode("express_code")
                                      },
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.emitInputCode("express_code")
                                      },
                                    },
                                    model: {
                                      value: _vm.editForm.express_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "express_code",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "editForm.express_code",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          slot: "prepend",
                                          placeholder: "请选择运输商",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.emitInputCode(
                                              "express_company"
                                            )
                                          },
                                        },
                                        slot: "prepend",
                                        model: {
                                          value: _vm.editForm.express_company,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm,
                                              "express_company",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editForm.express_company",
                                        },
                                      },
                                      _vm._l(
                                        _vm.expressCompany,
                                        function (item) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: item.value,
                                              attrs: {
                                                label: item.title,
                                                value: item.value,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " [" +
                                                  _vm._s(item.label) +
                                                  "]" +
                                                  _vm._s(item.title) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-input",
                        attrs: { label: "包裹号/箱号", prop: "codes" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "多个单号用英文逗号 , 分隔",
                            rows: 2,
                          },
                          on: { blur: _vm.parseCode },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.parseCode.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.inputCodeStr,
                            callback: function ($$v) {
                              _vm.inputCodeStr = $$v
                            },
                            expression: "inputCodeStr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.codesList },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "code", label: "单号" },
                            }),
                            _vm.editForm.type == "outbound"
                              ? _c("el-table-column", {
                                  attrs: { label: "出仓单号" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "出仓单号",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    scope.row.stockout_code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "stockout_code",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.stockout_code",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      slot: "prepend",
                                                      placeholder:
                                                        "请选择运输商",
                                                    },
                                                    slot: "prepend",
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .stockout_company,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "stockout_company",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.stockout_company",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.expressCompany,
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1822780317
                                  ),
                                })
                              : _vm._e(),
                            _vm.editForm.type == "box" ||
                            _vm.editForm.type == "package"
                              ? _c("el-table-column", {
                                  attrs: { label: "头程单号" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "头程单号",
                                                  clearable: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.emitRowInputCode(
                                                      scope.$index,
                                                      scope.row,
                                                      "feeder_code"
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.emitRowInputCode(
                                                      scope.$index,
                                                      scope.row,
                                                      "feeder_code"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.feeder_code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "feeder_code",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.feeder_code",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      slot: "prepend",
                                                      placeholder:
                                                        "请选择运输商",
                                                    },
                                                    slot: "prepend",
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .feeder_company,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "feeder_company",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.feeder_company",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.expressCompany,
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " [" +
                                                              _vm._s(
                                                                item.label
                                                              ) +
                                                              "]" +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1020656073
                                  ),
                                })
                              : _vm._e(),
                            _vm.editForm.type == "package"
                              ? _c("el-table-column", {
                                  attrs: { label: "末端单号" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "末端单号",
                                                  clearable: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.emitRowInputCode(
                                                      scope.$index,
                                                      scope.row,
                                                      "express_code"
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.emitRowInputCode(
                                                      scope.$index,
                                                      scope.row,
                                                      "express_code"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.express_code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "express_code",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.express_code",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      slot: "prepend",
                                                      placeholder:
                                                        "请选择运输商",
                                                    },
                                                    slot: "prepend",
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .express_company,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "express_company",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.express_company",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.expressCompany,
                                                    function (item) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " [" +
                                                              _vm._s(
                                                                item.label
                                                              ) +
                                                              "]" +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1632829426
                                  ),
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "操作",
                                width: "90",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "z-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            size: "small",
                                            plain: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCode(scope)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                          _vm._v("删除"),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "z-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.savePackageCodes },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }